import Vue from "vue";
import Vuex from "vuex";
import socketIO from "@/services/socketIO";

Vue.use(Vuex);

const socket = socketIO;
const startPoints = 30;

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * array.length);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

const play30game = {
  namespaced: true,
  state: {
    playerName: "",
    playerID: self.crypto.randomUUID(),
    currentPlayer: -1,
    gameRoom: { name: "", owner: false },
    players: [],
    tmpLockCount: 0,
    rollAgain: true,
    updating: [false, false, false, false, false, false],
    locks: [false, false, false, false, false, false],
    dice: [0, 0, 0, 0, 0, 0],
    targetScore: 0,
    targetLocks: [false, false, false, false, false, false],
    targetDice: [0, 0, 0, 0, 0, 0],
    targetUpdating: [false, false, false, false, false, false],
  },
  mutations: {
    async restartGame(state) {
      state.currentPlayer = -1;
      state.players.map((a) => (a.score = startPoints));
    },
    async setDice(state, payload) {
      state.dice[payload.index] = payload.value;
      state.dice = [...state.dice];
    },
    async setTargetDice(state, payload) {
      state.targetDice[payload.index] = payload.value;
      state.targetDice = [...state.targetDice];
    },
    async setDiceState(state, payload) {
      console.log("(M)setDiceState", payload);
      state.targetScore = payload.targetScore;
      state.dice = [...payload.dice];
      state.locks = [...payload.locks];
      state.updating = [...payload.updating];
      state.targetDice = [...payload.targetDice];
      state.targetLocks = [...payload.targetLocks];
      state.targetUpdating = [...payload.targetUpdating];
    },
    async lockDice(state, payload) {
      state.locks[payload - 1] = !state.locks[payload - 1];
      state.locks = [...state.locks];
      state.rollAgain = true;
    },
    async lockTargetDice(state, payload) {
      state.targetLocks[payload] = !state.targetLocks[payload];
      state.targetLocks = [...state.targetLocks];
      state.rollAgain = true;
    },
    async setLockedUpdating(state) {
      state.tmpLockCount = state.locks.reduce((s, a) => s + (a ? 1 : 0), 0);
      state.updating = [...state.locks]; //dont roll locked dice
      state.rollAgain = false;
    },
    async setDiceUpdating(state, payload) {
      state.updating[payload.i] = payload.state;
      state.updating = [...state.updating];
    },
    async setTargetLockedUpdating(state) {
      state.targetUpdating = [...state.targetLocks]; //lock permanent when locked
      state.rollAgain = false;
    },
    async setTargetDiceUpdating(state, payload) {
      state.targetUpdating[payload.i] = payload.state;
      state.targetUpdating = [...state.targetUpdating];
    },
    calculateScore(state, diceRollTotal) {
      const totalScore = diceRollTotal - 30;
      state.rollAgain = false;
      if (totalScore <= 0) {
        state.players[state.currentPlayer].score += totalScore;
      } else {
        state.targetScore = totalScore;
        state.rollAgain = true;
      }
    },
    hitTarget(state, payload) {
      state.rollAgain = false;
      if (payload.score > 0) {
        state.players[payload.player].score -= payload.score;
      }
    },
    setUpNextPlayer(state, nextPlayer) {
      state.currentPlayer = nextPlayer;

      state.rollAgain = true;
      state.updating = [false, false, false, false, false, false];
      state.locks = [false, false, false, false, false, false];
      state.dice = [0, 0, 0, 0, 0, 0];
      state.targetScore = 0;
      state.targetUpdating = [false, false, false, false, false, false];
      state.targetLocks = [false, false, false, false, false, false];
      state.targetDice = [0, 0, 0, 0, 0, 0];
    },
    setPlayerList(state, payload) {
      state.players = [...payload.playerList];
      state.currentPlayer = payload.player;
    },
    //setStartPlayer(state, { commit, dispatch }) {    },
    async handleDispatch(state, payload) {
      console.log("(M)handleMessages", state.currentPlayer, payload);
    },
    async setGameRoom(state, room) {
      console.log("(M)setGameRoom");
      state.gameRoom = room;
    },
    async setUserName(state, userName) {
      console.log("(M)setUserName");
      state.playerName = userName;
    },
    async joinGame(state, user) {
      state.players.push({
        name: user.user,
        score: startPoints,
        id: user.from,
        client: user.client,
      });
      function compare(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        return 1;
      }
      state.players.sort(compare);
    },
    async leaveGame(state, client) {
      console.log("(M)leaveGame", client);
      const clientIndex = state.players
        .map((a) => a.client)
        .indexOf(client.client);
      if (clientIndex >= 0) {
        state.players.splice(clientIndex, 1);
      }
    },
  },
  actions: {
    async initSocketListeners({ dispatch }) {
      console.log("@initSocketListeners");
      socket.addMessageListener("dispatch", (message, payload) => {
        console.log(message, payload);
        dispatch("handleMessages", payload);
      });
    },
    async initGame({ state, commit, dispatch }, gameRoom) {
      await dispatch("initSocketListeners").then(() => {
        const room = { from: state.playerID, room: gameRoom, public: false };
        socket.dispatchMessage("createRoom", room);
      });
      commit("setGameRoom", { name: gameRoom, owner: true });
    },
    async joinGame({ state, commit, dispatch }, gameRoom) {
      await dispatch("initSocketListeners").then(() => {
        const room = { from: state.playerID, room: gameRoom };
        socket.dispatchMessage("joinRoom", room);
      });
      commit("setGameRoom", { name: gameRoom, owner: false });
    },
    async restartGame({ commit, dispatch }) {
      await commit("restartGame");
      await dispatch("setUpNextPlayer");
      await dispatch("sendScoreUpdate");
      await dispatch("sendDiceUpdate");
    },
    async announcMe({ getters }) {
      const user = getters.getBaseMessage();
      user.action = "addUser";
      socket.dispatchMessage("dispatch", user);
    },
    async joinUser({ state, commit, dispatch }, userName) {
      commit("setUserName", userName);
      commit("joinGame", { from: state.playerID, user: state.playerName });
      dispatch("announcMe");
    },
    async handleMessages({ state, commit, dispatch, getters }, message) {
      if (state.playerID === message.from) {
        console.log("don't handle messages from self");
        return;
      }

      console.log("@handleMessages", message);

      if (message.action === "addUser") {
        const newPlayer = state.players.map((a) => a.id).indexOf(message.from);
        if (newPlayer < 0) {
          if (getters.getUserName !== "") dispatch("announcMe");
          if (state.currentPlayer < 0) commit("joinGame", message);
          else {
            dispatch("sendScoreUpdate"); //Send game status to spectators
          }
        }
      }

      if (message.action === "remUser") {
        commit("leaveGame", message);
      }

      if (message.action === "setPlayerList") {
        commit("setPlayerList", message.playerSetup);
      }

      if (message.action === "setDiceState") {
        commit("setDiceState", message.diceStatus);
      }

      if (
        message.action === "setDice" &&
        state.dice[message.dice.index] !== message.dice.value
      ) {
        commit("setDice", message.dice);
      }
      if (
        message.action === "setTargetDice" &&
        state.targetDice[message.dice.index] !== message.dice.value
      ) {
        commit("setTargetDice", message.dice);
      }

      //commit("handleDispatch", message);
    },
    async lockDice({ state, commit }, payload) {
      if (state.targetScore === 0) {
        commit("lockDice", payload);
      }
    },
    async setDice({ commit, getters }, payload) {
      console.log("setDice payload:", payload);
      commit("setDice", payload);
      const diceUpdate = getters.getBaseMessage();

      console.log("setDice baseMessage:", diceUpdate);
      diceUpdate.action = "setDice";
      diceUpdate.dice = payload;

      console.log("setDice complete:", diceUpdate);
      socket.dispatchMessage("dispatch", diceUpdate);
    },
    async rollDice({ state, commit, dispatch }) {
      await commit("setLockedUpdating");
      await dispatch("sendDiceUpdate");

      const rollDice = (state, i) => {
        if (!state.locks[i]) {
          commit("setDiceUpdating", { i: i, state: true });
          let intervalID = 0;

          //start dice with a small delay
          setTimeout(() => {
            intervalID = setInterval(() => {
              dispatch("setDice", {
                index: i,
                value: Math.floor(Math.random() * 6) + 1,
              });
            }, 200);
          }, 300);

          //stop dice after 1 to 5 sec
          setTimeout(() => {
            commit("setDiceUpdating", { i: i, state: false });
            clearInterval(intervalID);
          }, Math.random() * 5000 + 1000);
        }
      };

      for (let i = 0; i < 6; i++) {
        rollDice(state, i);
      }
    },
    async setTargetDice({ commit, getters }, payload) {
      commit("setTargetDice", payload);
      const diceUpdate = getters.getBaseMessage();
      diceUpdate.action = "setTargetDice";
      diceUpdate.dice = payload;
      socket.dispatchMessage("dispatch", diceUpdate);
    },
    async rollTargetDice({ state, commit, dispatch }) {
      commit("setTargetLockedUpdating");

      const rollTargetDice = (state, i) => {
        if (!state.targetLocks[i]) {
          commit("setTargetDiceUpdating", { i: i, state: true });

          let intervalID = 0;
          setTimeout(() => {
            intervalID = setInterval(() => {
              dispatch("setTargetDice", {
                index: i,
                value: Math.floor(Math.random() * 6) + 1,
              });
            }, 200);
          }, 200);

          setTimeout(() => {
            commit("setTargetDiceUpdating", { i: i, state: false });
            clearInterval(intervalID);
            //lock dice on target
            if (state.targetDice[i] === state.targetScore) {
              commit("lockTargetDice", i);
            }
          }, Math.random() * 5000 + 1000);
        }
      };

      for (let i = 0; i < 6; i++) {
        rollTargetDice(state, i);
      }
    },
    async sendDiceUpdate({ state, getters }) {
      const diceStatus = {
        targetScore: state.targetScore,
        updating: state.updating,
        locks: state.locks,
        dice: state.dice,
        targetDice: state.targetDice,
        targetLocks: state.targetLocks,
        targetUpdating: state.targetUpdating,
      };
      const diceUpdate = getters.getBaseMessage();
      diceUpdate.action = "setDiceState";
      diceUpdate.diceStatus = diceStatus;
      socket.dispatchMessage("dispatch", diceUpdate);
    },
    async sendScoreUpdate({ state, getters }) {
      const playerSetup = {
        player: state.currentPlayer,
        playerList: state.players,
      };
      const playerList = getters.getBaseMessage();
      playerList.action = "setPlayerList";
      playerList.playerSetup = playerSetup;
      socket.dispatchMessage("dispatch", playerList);
    },
    async calculateScore({ state, dispatch, commit, getters }) {
      await commit("calculateScore", getters.getLockedSum);
      if (!state.rollAgain) {
        await commit("setUpNextPlayer", getters.nextPlayer);
      }
      await dispatch("sendScoreUpdate");
      await dispatch("sendDiceUpdate");
    },
    async hitTarget({ dispatch, commit, getters }) {
      await commit("hitTarget", {
        score: getters.getTargetSum,
        player: getters.nextPlayer,
      });
      await commit("setUpNextPlayer", getters.nextPlayer);
      await dispatch("sendScoreUpdate");
      await dispatch("sendDiceUpdate");
    },
    async setStartPlayer({ state, commit, dispatch }) {
      //await commit("setStartPlayer", { commit, getters });

      var tmpPlayerList = [...state.players];
      var tmpPlayer = 0;

      function updatePlayerSetup() {
        const playerSetup = {
          player: tmpPlayer,
          playerList: tmpPlayerList,
        };
        commit("setPlayerList", playerSetup);
        dispatch("sendScoreUpdate");
      }

      updatePlayerSetup(); //init currentPlayer

      let intervalID = setInterval(() => {
        tmpPlayerList = [...shuffle(tmpPlayerList)];
        tmpPlayer = Math.floor(Math.random() * state.players.length);
        updatePlayerSetup();
      }, 100);
      setTimeout(() => {
        clearInterval(intervalID);
        updatePlayerSetup();
      }, Math.random() * 3000 + 1000);
    },
  },
  getters: {
    getBaseMessage: (state) => () => {
      return {
        from: state.playerID,
        user: state.playerName,
        room: state.gameRoom.name,
      };
    },
    getGameRoom: (state) => state.gameRoom.name,
    getUserName: (state) => state.playerName,
    getCurrentPlayerID: (state) => state.playerID,
    getCurrentPlayerIndex: (state) => state.currentPlayer,
    itsMyTurn: (state) => {
      console.log("(G)itsMyTurn", state.currentPlayer, state.players);
      return state.currentPlayer < 0
        ? false
        : state.playerID === state.players[state.currentPlayer].id;
    },
    getPlayer: (state) =>
      state.currentPlayer < 0 ? "" : state.players[state.currentPlayer].name,
    getTargetPlayer: (state, getters) => state.players[getters.nextPlayer].name,
    getPlayers: (state) => state.players,
    nextPlayer: (state) => {
      const next = state.players.reduce((n, t, i) => {
        if (state.currentPlayer < 0) return -2;
        if (n < 0 && t.score > 0) {
          return i;
        } else if (
          t.score > 0 &&
          i > state.currentPlayer &&
          n <= state.currentPlayer
        ) {
          return i;
        }
        return n;
      }, -1);
      return next;
      /*
      return state.currentPlayer + 1 === state.players.length
        ? 0
        : state.currentPlayer + 1;
      */
    },
    getRollAgain: (state, getters) => {
      if (state.currentPlayer < 0) return false;
      return (
        state.rollAgain &&
        (state.tmpLockCount <
          state.locks.reduce((s, a) => s + (a ? 1 : 0), 0) ||
          getters.getSum === 0)
      );
    },
    getScore: (state) => state.players[state.currentPlayer].score,
    getSum: (state) => {
      return state.dice.reduce((partialSum, a) => partialSum + a, 0);
    },
    getLockedSum: (state) => {
      return state.dice.reduce((s, a, i) => s + (state.locks[i] ? a : 0), 0);
    },
    getLocked: (state) => (index) => {
      return state.locks[index - 1];
    },
    allLocked(state) {
      return state.locks.reduce((partialSum, a) => partialSum && a, true);
    },
    getUpdating: (state) => (index) => {
      if (index !== undefined) {
        return state.updating[index - 1];
      }
      return state.updating.reduce((l, a, i) => {
        const updating = (a && !state.locks[i]) || l;
        return updating;
      }, false);
    },
    getDice: (state) => (index) => {
      return state.dice[index - 1];
    },
    getTarget: (state) => state.targetScore,
    getTargetSum: (state) => {
      return state.targetDice.reduce(
        (s, a, i) =>
          s + (state.targetLocks[i] && state.targetScore === a ? a : 0),
        0
      );
    },
    getTargetDice: (state) => (index) => {
      return state.targetDice[index - 1];
    },
    getTargetLocked: (state) => (index) => {
      return state.targetLocks[index - 1];
    },
    getTargetUpdating: (state) => (index) => {
      if (index !== undefined) {
        return state.targetUpdating[index - 1] && !state.targetLocks[index - 1];
      }
      return state.targetUpdating.reduce((l, a, i) => {
        const updating = (a && !state.targetLocks[i]) || l;
        return updating;
      }, false);
    },
  },
};

export default play30game;
