import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import play30game from "./modules/play30Game.js";

/*
import socket from "@/plugins/socketioService.js";

const socketio = socket();

// Just for testing purposes
const myPlugin = (store) => {
  console.log("myPlugin loaded");
  store.subscribe((mutation) => {
    console.log("@mutation", mutation.type, mutation.payload);
  });
  store.subscribeAction((action) => {
    console.log("@action", action.type, action.payload);
  });
};
*/
export default new Vuex.Store({
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    play30game: play30game,
  },
  //plugins: [socketio],
});
