import { io } from "socket.io-client";

class socketIO {
  socket;
  constructor() {
    //console.log("creating socket.io connection");
  }
  connectToServer() {
    const split = String(window.location.origin).split(":");
    const host1 = split[0] + ":" + split[1];
    console.log(host1);
    const host =
      process.env.NODE_ENV === "production"
        ? "https://cybersite.dk"
        : "http://localhost";
    console.log(host);

    //"localhost" //process.env.VUE_APP_SOCKET_ENDPOINT //"https://cybersite.dk"

    this.socket = io(host, {
      path: "/socketio/socket.io/",
      transports: ["websocket", "polling"],
    });
    console.log(this.socket);
  }

  addMessageListener(msg_type, f) {
    if (!this.socket) {
      this.connectToServer();
    }
    this.socket.on(msg_type, (message) => {
      console.log("@socketIO on " + msg_type, message);
      f(msg_type, message);
    });
  }

  dispatchMessage(msg_type, message) {
    console.log("@socketIO emit: " + msg_type, message);
    this.socket.emit(msg_type, message);
  }
}
export default new socketIO();
