import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/30/:id?/:user?",
    name: "30",
    component: () =>
      import(/* webpackChunkName: "30" */ "../views/Play30View.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
